import { LoaderGlobal } from '@bloomays-lib/ui.shared';
import { Suspense, useEffect } from 'react';
import { useAuthStore, useUserStore } from '../stores';
import { useApolloClient } from '@apollo/client';

const UserData = ({ children }: { children: React.ReactNode }) => {
  const user = useUserStore((state) => state.user);
  const userError = useUserStore((state) => state.userError);
  const auth = useAuthStore((state) => state.auth);
  const userFetch = useUserStore((state) => state.userFetch);
  const userInit = useUserStore((state) => state.userInit);
  const setApolloClient = useUserStore((state) => state.setApolloClient);
  const apolloClient = useApolloClient();

  useEffect(() => {
    (async () => {
      if (auth?.isAuthenticated === false) {
        await userInit();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if (userError) {
    throw userError;
  }

  if (auth?.isAuthenticated && user === undefined) {
    if (apolloClient) {
      setApolloClient(apolloClient);
    }
    throw userFetch('!user');
  }

  return children;
};

export function UserProvider({ children }: { children: React.ReactNode }) {
  return (
    <Suspense fallback={<LoaderGlobal waitingText="Chargement du profil utilisateur"></LoaderGlobal>}>
      <UserData>{children}</UserData>
    </Suspense>
  );
}
