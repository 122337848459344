import { styled } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore, useNotificationStore } from '../../stores';
import { Footer, Header, MenuItem } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { Role } from '@bloomays-lib/types.shared';
type BucketProps = {
  showFooter: boolean;
};

const Bucket = ({ showFooter = false }: BucketProps): JSX.Element => {
  const auth = useAuthStore((state) => state.auth);
  const user = auth?.user;
  const navigate = useNavigate();


  const { t } = useTranslation('menu');

  const isAdmin = user?.roles?.includes(Role.Admin);
  const isBloomer = user?.roles?.includes(Role.Bloomer);
  const isAnonymous = user?.roles?.includes(Role.Anonymous);

  return (
    <ContainerBucket>
      <Header
        returnToDashboard={() => navigate('/dashboard')}
        logout={auth?.logout}
        bloomer={user?.bloomer}
        user={{ picture: user?.picture || '', displayName: user?.displayName || '' }}
        notificationCenterContext={useNotificationStore()}
        menuItems={
          <>
            {user && !isAnonymous && <MenuItem name={t('dashboard')} url="/dashboard" shape="square" />}
            {isAdmin && (
              <div>
                <MenuItem name={t('planningMission')} url="/planningMission" shape="square" />
                <MenuItem name={t('billingsActivity')} url="/billingsActivity" shape="square" />
                <MenuItem name={t('finance')} url="/finance" shape="square" />
                <MenuItem name={t('talents')} url="/talents" shape="square" />
                <MenuItem name={t('activities')} url="/activities" shape="square" />
                <MenuItem name={t('legalContracts')} url="/contracts" shape="square" />
              </div>
            )}

            {isBloomer && <MenuItem name={t('missions')} url="/missions" shape="square" />}
            {isBloomer && <MenuItem name={t('meInformations')} url="/me/informations" shape="square" />}
            {(isBloomer || isAdmin) && <MenuItem name={t('settings')} url="/settings" shape="square" />}
            {(isBloomer || isAnonymous) && (
              <MenuItem name="BloomHelp" url="https://bit.ly/bloomays-help" shape="square" />
            )}
          </>
        }
      ></Header>
      <Outlet />
      {showFooter ? <Footer release={import.meta.env.VITE_RELEASE} env={import.meta.env.VITE_ENV} /> : ''}
    </ContainerBucket>
  );
};

export default Bucket;

const ContainerBucket = styled('div')(() => ({
  margin: 0,
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'grid',
  gridAutoColumns: 'minmax(auto, 100%)',
  gridTemplateRows: '12.6vh auto 10vh',
}));
