import { styled } from '@mui/material/styles';
import LogoBloomays from '../../graphic-charter/logoWordmark.png';

import { Logo, SingleLineText, LoginButton } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { useAuthStore } from '../../stores';

const LoginForm = (): JSX.Element => {
  const auth = useAuthStore((state) => state.auth);
  return (
    <ContainerLoginPage>
      <LogoSmallerBloomays image={LogoBloomays} />
      <TitlePart textTitle={"Bienvenue sur l'outil Bloomays !"} />
      <SingleLineText text="Connecte-toi à ton espace personnel" />
      <ContainerLoginButton>
        <LoginButton login={() => auth?.loginWithRedirect({ appState: { returnTo: '/' } })} />
      </ContainerLoginButton>
    </ContainerLoginPage>
  );
};

export default LoginForm;

const ContainerLoginPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 15,
  justifyContent: 'center',
  backgroundColor: theme.palette.paper.white,
  margin: 'auto',
  padding: '4em 0.5em',
  width: '25%',
  boxShadow: '2px 2px 5px rgb(206, 206, 206)',
  zIndex: 1,
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    width: '35%',
  },
  [theme.breakpoints.down('md')]: {
    width: '55%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '75%',
  },
}));

const LogoSmallerBloomays = styled(Logo)(({ theme }) => ({
  width: '60%',
  margin: '0 auto 2em',
}));

const ContainerLoginButton = styled('div')(({ theme }) => ({
  margin: '2em auto 0',
}));
