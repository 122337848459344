import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import fakeBG from '../../graphic-charter/fakeSignBG.png';
import Modal from '../atoms/Modal';
import NPSMission from '../organisms/NPSMission';
import { GET_MISSION_FOLLOWUP, UPSERT_NPS_FOLLOWUP } from '@bloomays-lib/adapter.api-bloomer';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { errorLogger } from '../../helpers/error';

const Container = styled('div')(() => ({
  backgroundImage: `url(${fakeBG})`,
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  backgroundPosition: 'top',
}));

const SatisfactionClient = (): JSX.Element => {
  const { id, month, recordId } = useParams<{
    id: string;
    month: string;
    recordId: string;
  }>();

  if (!recordId) {
    throw new Error('Missing recordId params');
  }

  const [selected, setSelected] = useState<number | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [clientFollowUp] = useMutation(UPSERT_NPS_FOLLOWUP);
  const { loading: loadingClient, data: dataClient } = useQuery(GET_MISSION_FOLLOWUP, {
    variables: {
      month: month,
      type: 'client',
      activityId: recordId,
    },
  });
  const { loading: loadingBLoomer, data: dataBloomer } = useQuery(GET_MISSION_FOLLOWUP, {
    variables: {
      month: month,
      type: 'bloomer',
      activityId: recordId,
    },
  });
  const [stateNPS, setStateNPS] = useState({
    loading: false,
  });
  const { t } = useTranslation('random');

  const handleClose = () => {
    setOpen(false);
  };

  const upsertFollowUp = async (args: { selected?: number; comment?: string; activityId: string }) => {
    try {
      setStateNPS({ ...stateNPS, loading: true });
      const followUp = await clientFollowUp({
        variables: {
          input: {
            score: args.selected,
            comment: args?.comment,
            collectDate: month,
            activityId: recordId,
          },
        },
      });
      setStateNPS({ ...stateNPS, loading: false });
      return followUp;
    } catch (error: any) {
      errorLogger(error, {
        extraInfos: 'Nps client follow up failed',
        state: { signingId: id },
      });
      setStateNPS({ ...stateNPS, loading: false });
    }
  };

  React.useEffect(() => {
    if (dataClient?.getMissionFollowUp?.recordId) {
      navigate(`/signing/${id}`);
    }
  }, [dataClient?.getMissionFollowUp?.recordId, id, navigate]);

  if (loadingClient || loadingBLoomer) return <LoaderSkeleton label={t('loadingText')} height={600} width={800} />;

  const getPlaceholder = () => {
    if (selected && selected <= 6) {
      return t('negativePHClient', { ns: 'NPSMission' });
    } else {
      return t('placeholder', { ns: 'NPSMission' });
    }
  };

  return (
    <Container>
      <Modal
        open={open}
        onClose={() => undefined}
        noClose={true}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <NPSMission
          stateNPS={stateNPS}
          onClick={async () => {
            await upsertFollowUp({
              comment,
              selected,
              activityId: recordId,
            });
            handleClose();
            navigate(`/signing/${id}`);
          }}
          setSelected={(e: number) => setSelected(e)}
          setComment={(e: any) => setComment(e.target.value)}
          selected={selected}
          comment={comment}
          noteBloomer={dataBloomer?.getMissionFollowUp?.score}
          commentBloomer={dataBloomer?.getMissionFollowUp?.comment}
          texts={{
            title: t('titleClient', {
              ns: 'NPSMission',
              bloomerFullname: dataBloomer?.getMissionFollowUp?.fullName,
              missionLabel: dataBloomer?.getMissionFollowUp?.missionLabel,
              bold: <strong />,
            }),
            placeholder: getPlaceholder(),
            button: t('buttonSignclient', { ns: 'NPSMission' }),
            details: t('detailsClient', { ns: 'NPSMission' }),
            nameLink: t('bloomeHelp', { ns: 'NPSMission' }),
            url: 'https://bit.ly/bloomays-help',
            btnTitle: t('thanksToNote', { ns: 'NPSMission' }),
            feedbackBloomerTitle: t('feedbackBloomer', {
              ns: 'NPSMission',
            }),
          }}
        />
      </Modal>
    </Container>
  );
};

export default WithErrorBoundary(SatisfactionClient);
