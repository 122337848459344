import { styled } from '@mui/material/styles';
import { ITalent } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import path from 'path';
import { FileViewer } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { Upload } from '../molecules/Upload';
import { useEffect, useState } from 'react';
import { Logger } from '../../services/serviceLogger';
import { useAuthStore } from '../../stores';

const logger = Logger('TalentSheetCV');
export type TalentSheetCVProps = {
  talent?: ITalent;
  uploadCv: any;
  CVRef: React.MutableRefObject<null>;
};

const TalentSheetCV = ({ talent, uploadCv, CVRef }: TalentSheetCVProps) => {
  const { t } = useTranslation(['talentSheet', 'random']);
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuthStore((state) => state.auth);

  useEffect(() => {
    if (auth?.getAccessTokenSilently) {
      auth.getAccessTokenSilently().then((token) => {
        setToken(token);
      });
    }
    logger.debug('auth token not ready 🕦', auth);
  }, [auth]);

  const isLegacyCVLinkedinURL = talent?.cv?.includes('linkedin');

  return (
    <div>
      <StyledXP>
        <Upload
          id="upload"
          ref={CVRef}
          titleModal={t('titleModalUploadCV1')}
          subTitleModal={t('titleModalUploadCV2')}
          placeholder="CV"
          fileTypes={['.pdf']}
          textLabel={t('updateCV', { ns: 'talentSheet' })}
          maxFiles={1}
          required={false}
          displayIcons={false}
          autoload={true}
          preloadedFiles={
            talent?.cv && !isLegacyCVLinkedinURL ? [{ url: talent.cv, path: path.basename(talent.cv) }] : []
          }
          handleChange={async (files) => {
            return await uploadCv(files);
          }}
          maxLength={5000000}
        />

        {talent?.cv && !isLegacyCVLinkedinURL ? (
          <FileViewer accessToken={token} url={talent?.cv} height={'1000'} />
        ) : (
          <div>
            <SingleLineText text={t('noCvOrLK', { ns: 'random' })} />
          </div>
        )}
      </StyledXP>
    </div>
  );
};

export default TalentSheetCV;
export { TalentSheetCV };

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 24px 24px 32px 24px;
  margin-bottom: 24px;
  `,
);
