/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import LabelAndTextArea from '../molecules/LabelAndTextarea';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useAuthStore } from '../../stores';

const Settings = () => {
  const [token, setToken] = useState<string | null | void>('');
  let isAuthenticated: boolean;
  let getAccessTokenSilently: (() => void) | undefined;
  const auth = useAuthStore((state) => state.auth);
  if (auth) {
    isAuthenticated = auth.isAuthenticated;
    getAccessTokenSilently = auth?.getAccessTokenSilently;
  }

  React.useEffect(() => {
    async function fetchToken() {
      if (token !== '') {
        return;
      }
      if (getAccessTokenSilently) {
        const _token = isAuthenticated ? await getAccessTokenSilently() : null;
        setToken(_token);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchToken();
    // @ts-ignore
  }, [getAccessTokenSilently, isAuthenticated, token]);

  return (
    <ContainerForm>
      <TitlePart id="apiToken" span="API Token" />
      <SingleLineText text={`Ton token d'api est :`} />
      <Form>
        <LabelAndTextArea
          name="Ton token API"
          textLabel="Token"
          value={token || ''}
          required={false}
          readOnly={true}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleChange={() => {}}
        />
      </Form>
    </ContainerForm>
  );
};

export default Settings;

const ContainerForm = styled('div')(() => ({
  width: '100%',
  margin: 'auto',
}));

const Form = styled('form')(() => ({
  padding: '0.8em',
  width: '85%',
  margin: '1.5em auto 0',
  position: 'relative',
}));
