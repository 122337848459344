import { ApolloError } from '@apollo/client';
import { NotificationCenterItemData } from '@bloomays-lib/types.shared';
import { NotificationCenterItem as ToastifyNotificationCenterItem } from 'react-toastify/addons/use-notification-center';

export enum TypeInput {
  text = 'text',
  select = 'select',
  number = 'number',
  url = 'url',
  email = 'email',
  password = 'password',
  tel = 'tel',
  date = 'date',
  hidden = 'hidden',
}

export type NotificationCenterItem = ToastifyNotificationCenterItem<NotificationCenterItemData>;

export type NotificationCenterContext = {
  notificationsLoading: boolean;
  notifications?: NotificationCenterItem[];
  notificationsError: ApolloError | Error | undefined;
  notificationsFetch: () => Promise<NotificationCenterItem[]>;
  notificationsRead: (notification: NotificationCenterItem) => Promise<boolean>;
  notificationsReadAll: () => Promise<number>;
  notificationsDeleteAll: () => Promise<number>;
};
