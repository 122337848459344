import { useLocation } from 'react-router-dom';

import { FileViewer } from '@bloomays-lib/ui.shared';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../stores';

const File = (): JSX.Element => {
  const location = useLocation();
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuthStore((state) => state.auth);

  const [maxHeight, setMaxHeight] = useState<string | undefined>();
  useEffect(() => {
    setMaxHeight((window.outerHeight - 10).toString());
  }, []);

  useEffect(() => {
    if (auth?.getAccessTokenSilently) {
      auth.getAccessTokenSilently().then((token) => {
        setToken(token);
      });
    }
  }, [auth]);

  return <FileViewer url={location.pathname} accessToken={token} width="100%" height={maxHeight} />;
};

export { File };
export default WithErrorBoundary(File);
