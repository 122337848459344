import { IJobFunnelProject } from '@bloomays-lib/types.shared';
import { styled } from '@mui/material/styles';
import SingleLineText from '../atoms/SingleLineText';
import { getDealTypeLabel } from '@bloomays-lib/utils.shared';

type JobFunnelProjectViewerProps = {
  jobFunnelProject: Partial<IJobFunnelProject>;
};

const JobFunnelProjectViewer = ({ jobFunnelProject }: JobFunnelProjectViewerProps) => {
  return (
    <div data-testid="job-funnel-viewer-global">
      <SingleLineText text={jobFunnelProject?.name} variant="heading4" sx={{ marginBottom: '15px' }} />
      <SingleLinePropertie
        title="Type de job :"
        value={jobFunnelProject.dealType ? getDealTypeLabel(jobFunnelProject?.dealType) : null}
      />
      <SingleLinePropertie title="Titre du poste :" value={jobFunnelProject?.jobTitle} />
      <FormatedLinePropertie title="Scorecard 1 :" value={jobFunnelProject?.scoreCard1} />
      <FormatedLinePropertie title="Scorecard 2 :" value={jobFunnelProject?.scoreCard2} />
      <FormatedLinePropertie title="Scorecard 3 :" value={jobFunnelProject?.scoreCard3} />
      <FormatedLinePropertie title="Scorecard 4 :" value={jobFunnelProject?.scoreCard4} />
      <FormatedLinePropertie title="Contexte :" value={jobFunnelProject?.context} />
      <FormatedLinePropertie title="Details de la mission :" value={jobFunnelProject?.details} />
      <FormatedLinePropertie title="L'équipe :" value={jobFunnelProject?.team} />
      <FormatedLinePropertie title="Déroulement des entretiens :" value={jobFunnelProject?.interviewProcess} />
      <SingleLinePropertie title="Lieu de la mission :" value={jobFunnelProject?.location} />
      <SingleLinePropertie
        title="Durée de la mission (en mois) / PE :"
        value={jobFunnelProject?.duration?.toString()}
      />
      <SingleLinePropertie
        title="Nombre de jour de travail par semaine :"
        value={jobFunnelProject?.onSiteDaysPerWeek?.toString()}
      />
      <SingleLinePropertie
        title="Nombre de jour de remote par semaine :"
        value={jobFunnelProject?.remoteDaysPerWeek?.toString()}
      />
      <SingleLinePropertie title="Budget client max :" value={jobFunnelProject?.maxClientBudget?.toString()} />
      <FormatedLinePropertie title="Détails budget client :" value={jobFunnelProject?.clientBudgetDetails} />
      <SingleLinePropertie title="Budget talent max :" value={jobFunnelProject?.maxTalentBudget?.toString()} />
    </div>
  );
};

const SingleLinePropertie = ({ title, value }: { title: string; value?: string | null }) => (
  <PropertieLine>
    <SingleLineText text={title} variant="body2SemiBold" />
    <SingleLineText text={value} />
  </PropertieLine>
);

const FormatedLinePropertie = ({ title, value }: { title: string; value?: string | null }) => (
  <PropertieLine direction="column">
    <details open={!!value}>
      <StyledSummary>
        <SingleLineText text={title} variant="body2SemiBold" />
      </StyledSummary>
      <FormatedText>{value}</FormatedText>
    </details>
  </PropertieLine>
);

export default JobFunnelProjectViewer;
export { JobFunnelProjectViewer };

const PropertieLine = styled('div')<{ direction?: 'row' | 'column' }>(({ direction = 'row' }) => ({
  margin: '5px 0',
  display: 'flex',
  flexDirection: direction,
  gap: direction === 'row' ? '5px' : '0',
  textAlign: 'left',
}));

const StyledSummary = styled('summary')(({ theme }) => ({
  ':hover': {
    cursor: 'pointer',
  },
  span: {
    display: 'unset',
  },
}));

const FormatedText = styled('pre')(() => ({
  margin: '5px 0 0 0',
  fontFamily: 'Poppins Light',
  fontSize: '14px',
  whiteSpace: ' pre-wrap',
}));
