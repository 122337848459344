import React, { useState } from 'react';
import MuiMenu from '@mui/material/Menu';
import Avatar from '../atoms/Avatar';

export type MenuAvatarProps = {
  user?: { picture?: string; displayName: string };
  children: React.ReactNode;
};

const MenuAvatar = ({ user, children }: MenuAvatarProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | (() => Element) | null | undefined>(null);
  const [isHovered, setIsHovered] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      id="basic-menu"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        cursor: isHovered ? 'pointer' : 'auto',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Avatar
        image={user?.picture ? user.picture : ''}
        size="medium"
        alt={user?.displayName || 'Avatar'}
        onClick={handleClick}
      />
      <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {children}
      </MuiMenu>
    </div>
  );
};

export default MenuAvatar;
export { MenuAvatar };
