import React from 'react';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Role } from '@bloomays-lib/types.shared';
import { ComponentType } from 'react';
import { LoaderGlobal } from '@bloomays-lib/ui.shared';
import { useAuthStore, useUserStore } from '../stores';
interface ProtectedRouteProps {
  Component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ Component }: ProtectedRouteProps): JSX.Element => {
  const auth = useAuthStore((state) => state.auth);
  const bloomaysUser = useUserStore((state) => state.user)?.bloomer;
  const authRoles = auth?.user?.roles || [];
  const authUser = auth?.user;
  if (!auth?.isAuthenticated) {
    auth?.loginWithRedirect({
      appState: { returnTo: `${window.location.pathname}${window.location.search}` },
    });
    return <LoaderGlobal waitingText="Redirection vers l'authentification" />;
  }

  if (authUser?.roles?.includes(Role.Anonymous)) {
    return <Component />;
  }
  if (auth?.isAuthenticated && authUser?.email_verified === false) {
    return <Navigate to="/forbidden/norole" />;
  }

  if (auth?.isAuthenticated && authRoles.length === 1 && authRoles.includes(Role.Bloomer) && !bloomaysUser) {
    return <Navigate to="/forbidden/notrecognized" />;
  }

  if (bloomaysUser?.invitation) {
    if (
      bloomaysUser.invitation?.status !== 'done' &&
      !(window.location.href.indexOf(`${bloomaysUser.invitation.token}`) > -1)
    ) {
      return <Navigate to={`/invite/${bloomaysUser.invitation.token}`} />;
    }
  }

  const ComponentAuthenticate = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoaderGlobal waitingText="Redirection vers l'authentification" />,
  });
  return <ComponentAuthenticate />;
};
export default ProtectedRoute;
