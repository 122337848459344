import { StateCreator, create } from 'zustand';
import { Auth0Context, AuthBloomaysContext } from '@bloomays-lib/types.shared';
import { Logger } from '../services/serviceLogger';
import { AuthFactory } from '../auth/authService';
const logger = Logger('authStore');

export interface AuthState {
  auth?: AuthBloomaysContext;
  authLoading: boolean;
  authError?: Error;
  authFetch: (auth0: Auth0Context, location: string) => Promise<void>;
}

export const createAuthSlice: StateCreator<AuthState> = (set) => {
  return {
    auth: undefined,
    authLoading: true,
    authError: undefined,
    authFetch: (auth0, location) => {
      return new Promise((resolve, reject) => {
        logger.debug('authFetch 👀', auth0);
        if (auth0.isLoading) {
          set({ authLoading: true });
          return resolve();
        }
        const result = AuthFactory({ auth0context: auth0, location });
        try {
          set({ auth: result, authLoading: false });
          logger.debug('authFetch ✅', auth0);
          return resolve();
        } catch (error: any) {
          const managedError = new Error("Impossible de charger le profil d'authentification", { cause: error });
          set({ authError: managedError, authLoading: false });
          logger.debug('authFetch ❌', auth0);
          return reject(managedError);
        }
      });
    },
  };
};

export const useAuthStore = create<AuthState>()((...a) => ({
  ...createAuthSlice(...a),
}));
