import { StateCreator, create } from 'zustand';
import { setContext } from '@apollo/client/link/context';

import { ApolloClient, InMemoryCache, split, from } from '@apollo/client';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { errorLinkMonitoring } from '@bloomays-lib/common.monitoring/browser';
import {
  ApolloErrorTypes,
  getGQLErrorCode,
  getNetworkErrorCode,
  isGraphQFormattedLError,
  isGraphQLError,
} from '../helpers/error';
import { getErrorStatusCode } from '@bloomays-lib/utils.shared';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useAuthStore } from './authStore';
import { Logger } from '../services/serviceLogger';
const logger = Logger('apolloStore');
const { VITE_BLOOMERS_API, VITE_TALENTS_API } = import.meta.env;

export interface ApolloState {
  apolloClient?: ApolloClient<any>;
  apolloClientError?: Error;
  initializeApolloClient: () => Promise<ApolloClient<any>>;
  apolloLoading: boolean;
}

const apolloClientFactory = (getRefreshToken: () => Promise<string | undefined>) => {
  const url = new URL(VITE_BLOOMERS_API || 'localhost:4000');
  const secure = url.protocol.includes('https');

  const wsLinkBloomer = new GraphQLWsLink(
    createClient({
      url: `ws${secure ? 's' : ''}://${url.host}/subscriptions`,
    }),
  );

  const httpLinkBloomers = createUploadLink({
    uri: VITE_BLOOMERS_API,
    credentials: 'include',
  });

  const httpLinkTalents = createUploadLink({
    uri: `${VITE_TALENTS_API}graphql`,
    credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getRefreshToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'Apollo-Require-Preflight': 'true',
      },
    };
  });

  const filterCodes = ['TOKEN_EXPIRED', 'UNKNOWN_BLOOMER'];

  const filterExceptions = (error: ApolloErrorTypes) => {
    let code: string | undefined;
    if (isGraphQLError(error)) {
      code = getGQLErrorCode(error);
    } else if (isGraphQFormattedLError(error)) {
      code = getGQLErrorCode(error);
    } else {
      code = getNetworkErrorCode(error);
    }
    return filterCodes.includes(code || '') || getErrorStatusCode(error) < 500;
  };

  const multiLinkBloomer = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLinkBloomer,
    httpLinkBloomers,
  );

  const monitLink = errorLinkMonitoring({ filterExceptions });

  return new ApolloClient({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link: split(
      (operation) => {
        return operation.getContext().clientName === 'api.talents';
      },
      from([monitLink, authLink, httpLinkTalents]), //if above
      from([monitLink, authLink, multiLinkBloomer]),
    ),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Bloomer: { keyFields: ['recordId'], merge: true },
        Notification: { keyFields: ['recordId'], merge: true },
        Mission: { keyFields: ['recordId'] },
        Acivity: { keyFields: ['recordId'] },
        Invitation: { keyFields: ['recordId'] },
        Society: { keyFields: ['recordId'] },
        Contact: { keyFields: ['recordId'] },
        Pricing: { keyFields: ['recordId'] },
        Query: {
          fields: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allMissions: { missions: offsetLimitPagination(['isActive']) },
            me: {
              // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
              merge: true,
            },
          },
        },
      },
    }),
  });
};

export const createApolloSlice: StateCreator<ApolloState> = (set, get) => {
  return {
    apolloClient: undefined,
    apolloLoading: true,
    apolloClientError: undefined,
    initializeApolloClient: () => {
      return new Promise((resolve, reject) => {
        logger.debug('Apollo client 👀');
        const auth = useAuthStore((state) => state.auth);

        if (!auth) {
          const error = new Error('Auth Not Initialized');
          set({ apolloLoading: false, apolloClientError: error });
          return reject(error);
        }

        const apolloClient = apolloClientFactory(auth.getAccessTokenSilently);
        set({ apolloClient: apolloClient, apolloLoading: false });
        if (!auth.isAuthenticated) {
          logger.debug('Apollo client ☑️');
        } else {
          logger.debug('Apollo client  ✅');
        }
        return resolve(apolloClient);
      });
    },
  };
};

export const useApolloStore = create<ApolloState>()((...a) => ({
  ...createApolloSlice(...a),
}));
