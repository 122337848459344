import { BrowserBloomer, IBloomer } from './types.shared';
import type { BaseContext } from '@apollo/server';
import type { Request } from 'express';
import type {
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  AppState,
  LogoutOptions as Auth0LogoutOptions,
} from '@auth0/auth0-react';

export type AuthInfos = {
  user: IUser;
  token?: string;
};

export type AuthRequest = Request & {
  user: AuthInfos;
};

export type AuthContext = BaseContext & {
  req: AuthRequest;
};

export enum Role {
  Admin = 'Admin',
  Anonymous = 'Anonymous',
  Bloomer = 'Bloomer',
  System = 'System',
}

// ce type doit implementer la signature de la class Auth0 User
// ./node_modules/@auth0/auth0-spa-js/dist/typings/global.d.ts
export type Auth0User = {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
  id: string;
  provider?: string;
  roles?: Role[];
  displayName: string;
  'https://www.bloomays.com/roles': Role[];
};

export type LogoutOptions = Auth0LogoutOptions;

export type AuthBloomaysContext = {
  isAnonymous: boolean;
  user: Auth0User | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  error: Error | undefined;
  logout: (options?: LogoutOptions) => void;
  loginWithRedirect: (e: { appState: { returnTo: string } }) => void;
  getAccessTokenSilently: () => Promise<string | undefined>;
};

export type Auth0Context = {
  user: Auth0User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: Error | undefined;
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>;
  logout: (options?: LogoutOptions) => Promise<void>;
};

export interface Profil {
  id: string;
  provider: any;
  roles: Role;
  name?: Name;
  email: string;
  displayName: string;
}

export interface BrowserMe {
  profile: Profil;
  bloomer: BrowserBloomer;
}

export type Name = {
  familyName?: string;
  givenName?: string;
};

import { JwtPayload } from 'jwt-decode';

export interface AnonymousJwtPayload extends JwtPayload {
  statebag: any;
  user?: Auth0User;
}

export interface ClientEmbedInvitePayload extends AnonymousJwtPayload {
  statebag: {
    signingEmbedClientId: string;
    activityRecordId: string;
  };
}

export interface JobGuestHouseInvitePayload extends AnonymousJwtPayload {
  statebag: {
    jobId: string;
  };
}

export interface DecodedAnynomousToken extends AnonymousJwtPayload {
  token: string;
}

export interface LegalContractPayload extends AnonymousJwtPayload {
  statebag: {
    externalDocumentId: string;
    signerId: string;
  };
}

export interface IContext {
  bypass?: boolean | null;
  user?: IUser;
  args?: any;
  bloomer?: {
    recordId: string;
    mission?: string[];
    email: string;
    updateCache: (arg: IBloomer) => void;
  };
  token?: string | undefined;
  decodedToken?: JwtPayload;
}

export interface IUser {
  phoneNumber?: string;
  roles: string[];
  email: string;
  emailVerified?: boolean;
  displayName: string;
  name?: {
    givenName?: string;
    familyName?: string;
  };
}
