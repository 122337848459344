import React, { Suspense, useEffect } from 'react';
import { Auth0Context } from '@bloomays-lib/types.shared';
import { LoaderGlobal } from '@bloomays-lib/ui.shared';
import { useAuthStore } from '../stores';
import { setUser } from '@bloomays-lib/common.monitoring/browser';

const AuthData = ({
  children,
  auth0Context,
  location,
}: {
  children: React.ReactNode;
  auth0Context: Auth0Context;
  location: string;
}) => {
  const authFetch = useAuthStore((state) => state.authFetch);
  const authError = useAuthStore((state) => state.authError);
  const auth = useAuthStore((state) => state.auth);

  useEffect(() => {
    if (auth) {
      // si on a un changement de contexte, on recharge le jeton
      if (!auth.isAnonymous && auth.isAuthenticated !== auth0Context.isAuthenticated) {
        authFetch(auth0Context, location);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Context, location]);

  if (authError) {
    throw authError;
  }

  if (!auth) {
    throw authFetch(auth0Context, location);
  }

  if (auth?.user) {
    setUser(auth.user);
  }

  return children;
};

export function AuthProvider({
  children,
  auth0Context,
  location,
}: {
  children: React.ReactNode;
  auth0Context: Auth0Context;
  location: string;
}) {
  return (
    <Suspense fallback={<LoaderGlobal waitingText="Chargement du jeton utilisateur"></LoaderGlobal>}>
      <AuthData auth0Context={auth0Context} location={location}>
        {children}
      </AuthData>
    </Suspense>
  );
}
