import { Auth0Provider, AppState, Auth0Context } from '@auth0/auth0-react';
import { Children } from '../types';
import type { Auth0Context as Auth0ContextType } from '@bloomays-lib/types.shared';
import { useEffect, useMemo } from 'react';
import { Logger } from '../services/serviceLogger';
const logger = Logger('Auth0ProviderWithNavigate');
export { Auth0Context };

type Auth0ProviderWithNavigateProps = {
  children: Children;
  mockAuth0Context?: Auth0ContextType;
  auth0ProviderConfig: Auth0ProviderConfig;
};

export type Auth0ProviderConfig = {
  domain: string;
  clientId: string;
  audience?: string;
};

export const MockAuth0Provider = ({
  children,
  mockContext,
}: {
  children: React.ReactNode;
  mockContext: Auth0ContextType;
}) => {
  const mockedMemo = useMemo(() => {
    return {
      user: mockContext.user,
      isAuthenticated: mockContext.isAuthenticated,
      isLoading: mockContext.isLoading,
      getAccessTokenSilently: mockContext.getAccessTokenSilently as any,
      getAccessTokenWithPopup: () => Promise.resolve(undefined),
      getIdTokenClaims: () => Promise.resolve(undefined),
      loginWithRedirect: mockContext.loginWithRedirect,
      loginWithPopup: () => Promise.resolve(undefined),
      logout: mockContext.logout,
      handleRedirectCallback: () => Promise.resolve({ appState: '/' }),
    };
  }, [mockContext]);

  return (
    <Auth0Context.Provider key={mockContext.isAuthenticated ? 'auth' : 'unauth'} value={mockedMemo}>
      {children}
    </Auth0Context.Provider>
  );
};

export const Auth0ProviderWithNavigate = ({
  children,
  mockAuth0Context,
  auth0ProviderConfig,
}: Auth0ProviderWithNavigateProps): JSX.Element => {
  const onRedirectCallback = (appState?: AppState) => {
    window.location.replace(appState?.returnTo || '/');
  };

  useEffect(() => {
    logger.debug('mockAuth0Context change', mockAuth0Context);
  }, [mockAuth0Context]);

  if (mockAuth0Context) {
    return <MockAuth0Provider mockContext={mockAuth0Context}>{children}</MockAuth0Provider>;
  }

  return (
    <Auth0Provider
      domain={auth0ProviderConfig.domain}
      clientId={auth0ProviderConfig.clientId}
      authorizationParams={{
        audience: auth0ProviderConfig.audience,
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
};
