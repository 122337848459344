import { MemoryRouter, MemoryRouterProps, Route, RouterProvider, Routes, BrowserRouter } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import ToastContainer from '../components/molecules/ToastContainer';

import './app.css';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { getRouteConfig } from '../helpers/routes';
import { LoaderGlobal } from '@bloomays-lib/ui.shared';
import fr from '@bloomays-lib/utils.shared';
import { Theming } from '@bloomays-lib/ui.shared';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from '../auth/AuthProvider';
import { Auth0ProviderWithNavigate, Auth0Context, Auth0ProviderConfig } from '../auth/Auth0ProviderWithNavigate';
import AuthorizedApolloFactory from '../auth/AuthorizedApolloProvider';
import { NotificationProvider } from '../contexts/NotificationsProvider';
import { UserProvider } from '../contexts/UserProvider';
import type { Auth0Context as Auth0ContextType, Env } from '@bloomays-lib/types.shared';
import { GlobalErrorBoundary, ErrorBoundary } from '../components/organisms/ErrorBoundary';
import React, { ReactNode } from 'react';
import NotFound from '../components/pages/NotFound';
import AuthInfo from '../components/molecules/AuthInfo';
import { CRMVisitorLoader } from '../auth/CRMVisitorProvider';

LicenseInfo.setLicenseKey(
  '7567606fd7a63ea2f199ea4759f22411Tz0xMDE3OTAsRT0xNzYzMTk1ODM2MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      fr,
    },
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false,
    },
  });

const DefaultRouter = ({ children, injectedRouter }: { children: ReactNode; injectedRouter?: MemoryRouterProps }) => {
  if (injectedRouter) {
    return (
      <div data-testid="router">
        <MemoryRouter {...injectedRouter}>
          <Routes>
            <Route path="/dashboard" element={<AuthInfo />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          {children}
        </MemoryRouter>
      </div>
    );
  }
  return (
    <>
      <RouterProvider
        router={getRouteConfig()}
        fallbackElement={<LoaderGlobal waitingText="Chargement des routes" />}
      />
      <BrowserRouter>{children}</BrowserRouter>
    </>
  );
};

type AppProps = {
  router?: MemoryRouterProps;
  auth0ProviderConfig: Auth0ProviderConfig;
  mockAuth0Context?: Auth0ContextType;
  env: Env;
};

const App = ({ router, auth0ProviderConfig, mockAuth0Context, env }: AppProps) => {
  return (
    <Theming>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <GlobalErrorBoundary>
          <Auth0ProviderWithNavigate auth0ProviderConfig={auth0ProviderConfig} mockAuth0Context={mockAuth0Context}>
            <Auth0Context.Consumer>
              {(auth0ContextInterface) => (
                <AuthProvider auth0Context={auth0ContextInterface as Auth0ContextType} location={window.location.href}>
                  <AuthorizedApolloFactory env={env}>
                    <ErrorBoundary>
                      <UserProvider>
                        <CRMVisitorLoader />
                        <NotificationProvider>
                          <DefaultRouter injectedRouter={router}>
                            <ToastContainer />
                          </DefaultRouter>
                        </NotificationProvider>
                      </UserProvider>
                    </ErrorBoundary>
                  </AuthorizedApolloFactory>
                </AuthProvider>
              )}
            </Auth0Context.Consumer>
          </Auth0ProviderWithNavigate>
        </GlobalErrorBoundary>
      </StyledEngineProvider>
    </Theming>
  );
};

export default App;
