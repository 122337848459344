import jwt_decode from 'jwt-decode';
import { AnonymousJwtPayload, AuthBloomaysContext, Auth0Context, Auth0User } from '@bloomays-lib/types.shared';
import { Logger } from '../services/serviceLogger';
import clone from 'lodash/fp/clone';

const logger = Logger('AuthService');
export const GetAnonymousUser = (
  token: string | undefined,
  logout: () => void,
  loginWithRedirect: () => void,
): AuthBloomaysContext => {
  if (token && token.includes('anonymous:')) {
    logger.debug('GetAnonymousUser 👀', { token });
    try {
      const decodedToken: AnonymousJwtPayload = jwt_decode(token);
      const context: AuthBloomaysContext = {
        isAnonymous: true,
        user: decodedToken.user,
        isAuthenticated: true,
        isLoading: false,
        error: undefined,
        logout: logout,
        loginWithRedirect: loginWithRedirect,
        getAccessTokenSilently: () => Promise.resolve(token),
      };
      delete decodedToken.user;
      if (context.user) {
        context.user.email_verified = true;
      }
      logger.debug('GetAnonymousUser ✅', { token });
      return context;
    } catch (error: any) {
      const context: AuthBloomaysContext = {
        isAnonymous: true,
        user: undefined,
        isAuthenticated: false,
        isLoading: false,
        error,
        logout,
        loginWithRedirect,
        getAccessTokenSilently: () => Promise.resolve(undefined),
      };
      logger.debug('GetAnonymousUser ❌', context);
      return context;
    }
  }
  return {
    isAnonymous: true,
    error: new Error('No token found'),
    user: undefined,
    isAuthenticated: false,
    isLoading: false,
    logout,
    loginWithRedirect,
    getAccessTokenSilently: () => Promise.resolve(undefined),
  };
};

export const GetAuth0User = ({
  user,
  isLoading,
  isAuthenticated,
  error,
  getAccessTokenSilently,
  loginWithRedirect,
  logout,
}: Auth0Context): AuthBloomaysContext => {
  let clonedUser: Auth0User | undefined;
  if (user) {
    clonedUser = clone(user);
    logger.debug('GetAuth0User 👀', clonedUser);
    clonedUser.roles = user?.['https://www.bloomays.com/roles'] || [];
    clonedUser.displayName = user.name || user.displayName;
    delete clonedUser.name;
  }
  const context = {
    isAnonymous: false,
    user: clonedUser,
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  };
  logger.debug('GetAuth0User ✅', context);
  return context;
};

export const AuthFactory = (args: { auth0context: Auth0Context; location: string }): AuthBloomaysContext => {
  const query = new URL(args.location).searchParams;
  const qToken = query.get('token');
  if (qToken) {
    sessionStorage.setItem('token', qToken);
  }
  const token = qToken || sessionStorage.getItem('token') || undefined;
  const { user, isLoading, isAuthenticated, error, logout, loginWithRedirect, getAccessTokenSilently, isAnonymous } =
    GetAnonymousUser(token, args.auth0context.logout, args.auth0context.loginWithRedirect);
  if (!isAuthenticated) {
    return GetAuth0User(args.auth0context);
  }
  return { user, isLoading, isAuthenticated, error, logout, loginWithRedirect, getAccessTokenSilently, isAnonymous };
};
