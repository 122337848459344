import LogoBloomays from '../../assets/logoWordmark.svg';
import { BrowserBloomer, LogoutOptions, Role } from '@bloomays-lib/types.shared';
import { Logo as RawLogo } from '../atoms/Logo';
import LogoutButton from '../atoms/LogoutButton';
import MenuAvatar from './MenuAvatar';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { NotificationCenter } from '../organisms/NotificationCenter';
import { NotificationCenterContext } from '../types';

export type HeaderProps = {
  bloomer?: BrowserBloomer;
  user?: { picture?: string; displayName: string };
  logout?: (options?: LogoutOptions) => void;
  children?: React.ReactNode;
  returnToDashboard?: () => void;
  notificationCenterContext?: NotificationCenterContext;
  menuItems?: React.ReactNode;
};

const Header = ({
  user,
  bloomer,
  logout,
  children,
  menuItems,

  returnToDashboard,
  notificationCenterContext,
}: HeaderProps) => {
  const [notificationCenterItems, setNotificationCenterItems] = useState(
    notificationCenterContext?.notifications || [],
  );

  useEffect(() => {
    setNotificationCenterItems(notificationCenterContext?.notifications || []);
  }, [notificationCenterContext]);

  return (
    <Container>
      <Logo
        alt="logo bloomays"
        image={LogoBloomays}
        sx={
          returnToDashboard && {
            '&:hover': {
              cursor: 'pointer',
            },
          }
        }
        onClick={returnToDashboard ? () => returnToDashboard() : undefined}
      />
      <>
        {notificationCenterContext && notificationCenterItems ? (
          <NotificationCenter
            initNotifications={notificationCenterItems}
            onRead={notificationCenterContext?.notificationsRead}
            onReadAll={notificationCenterContext?.notificationsReadAll}
            onClearAll={notificationCenterContext?.notificationsDeleteAll}
          />
        ) : undefined}
        {user && (
          <MenuAvatar user={user}>
            <div>
              {menuItems}
              {logout && <LogoutButton type="link" logout={() => logout()} />}
            </div>
          </MenuAvatar>
        )}
      </>
      {children}
    </Container>
  );
};

export default Header;
export { Header };

const Logo = styled(RawLogo)(({ theme }) => ({
  marginRight: 'auto',
  padding: '1em',
  maxWidth: 180,
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '45%',
  },
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.paper.white,
  width: '100%',
  height: '80px',
  marginBottom: '2em',
  boxShadow: '0px 4.380000114440918px 24.100000381469727px 0px rgba(75, 75, 75, 0.09)',
}));
