import { useEffect } from 'react';
import { useAuthStore, useNotificationStore, useUserStore } from '../stores';
import { useApolloClient } from '@apollo/client';
const NotificationData = () => {
  const { notifications, notificationsFetch, notificationsError, setApolloClient } = useNotificationStore();
  const auth = useAuthStore((state) => state.auth);
  const user = useUserStore((state) => state.user);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (apolloClient) {
      setApolloClient(apolloClient);
    }
    if (auth?.isAuthenticated && !notifications && !notificationsError && user) {
      notificationsFetch();
    }
  }, [notifications, notificationsFetch, notificationsError, apolloClient, setApolloClient, auth, user]);

  if (notificationsError) {
    throw notificationsError;
  }

  return null;
};

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <NotificationData />
      {children}
    </>
  );
}
