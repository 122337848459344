import { styled } from '@mui/material/styles';
import LoadingLogo from '../atoms/LoadingLogo';
import SingleLineText from '../atoms/SingleLineText';

type LoaderGlobalProps = {
  waitingText?: string;
};

const LoaderGlobal = ({ waitingText }: LoaderGlobalProps) => {
  return (
    <LoaderContainer data-testid="loader-global">
      <LoadingLogo />
      <SingleLineText text={waitingText} sx={{ marginLeft: '10px' }} variant="body2SemiBold" />
    </LoaderContainer>
  );
};

export default LoaderGlobal;
export { LoaderGlobal };

const LoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center' /* Centre horizontalement */,
  alignItems: 'center' /* Centre verticalement */,
  flexDirection: 'column' /* Dispose les éléments verticalement */,
  height: '100vh' /* S'assure que le conteneur occupe toute la hauteur de la fenêtre */,
  //border: "1px solid #ccc", /* Juste pour voir les limites du conteneur */
}));
