import React, { Suspense } from 'react';

import { LoaderGlobal } from '@bloomays-lib/ui.shared';
import { ApolloProvider } from '@apollo/client';
import { useApolloStore } from '../stores/apolloStore';
import { Env } from '@bloomays-lib/types.shared';

type AuthorizedApolloProviderProps = {
  children: React.ReactNode;
  env: Env;
};

const AuthorizedApolloFactory = ({ children, env }: AuthorizedApolloProviderProps): JSX.Element => {
  const { apolloClient, apolloClientError, initializeApolloClient } = useApolloStore();

  if (apolloClientError) {
    throw apolloClientError;
  }
  if (!apolloClient) {
    throw initializeApolloClient();
  }
  if (env === 'test') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const AuthorizedApolloProvider = ({ children, env }: AuthorizedApolloProviderProps) => {
  return (
    <Suspense fallback={<LoaderGlobal waitingText="Chargement du contexte API"></LoaderGlobal>}>
      <AuthorizedApolloFactory env={env}>{children}</AuthorizedApolloFactory>
    </Suspense>
  );
};

export default AuthorizedApolloProvider;
