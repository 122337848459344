import { useNavigate } from 'react-router-dom';
import uniqWith from 'lodash/uniqWith';
import filter from 'lodash/filter';
import RawIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BloomerMission from '../molecules/BloomerMission';
import { Button, SingleLineText, NotificationDashboard } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import { TitlePart as RawTitlePart } from '@bloomays-lib/ui.shared';
import { IMission, ISociety } from '@bloomays-lib/types.shared';
import { useNotificationStore, useUserStore } from '../../stores';

const BloomerHome = () => {
  const dataMe = useUserStore((state) => state.user);

  const navigate = useNavigate();

  const bloomer = dataMe?.bloomer;
  const missions = bloomer?.mission;

  let billingSocietys: any =
    missions?.map((mission: IMission) => {
      return { ...mission?.bloomerBillingSociety?.[0], bloomerBillingMission: [mission] };
    }) || [];

  billingSocietys = filter(billingSocietys, (society) => society);

  const uniqueSociety = uniqWith(
    billingSocietys,
    (society: ISociety, otherSociety: ISociety) =>
      society?.siret === otherSociety?.siret &&
      society.bloomerBillingMission?.[0].recordId === otherSociety.bloomerBillingMission?.[0].recordId,
  );

  const name = bloomer?.fullname || '';

  const missionURL = missions?.length === 1 ? `/mission/${missions?.[0]?.recordId}` : '/missions';

  return (
    <ContainerBloomerDashboard>
      <Title textTitle="Bienvenue " span={`${name} 👋`} />
      <NotificationDashboard notificationCenterContext={useNotificationStore()} />
      <div>
        <Button
          textButton={'Gérer mes CRA'}
          onClick={() => navigate(missionURL)}
          emoji={<VisibilityIcon sx={{ marginRight: 2 }} />}
          sx={{ margin: '8px 0' }}
        />
      </div>
      <TitlePart textTitle={'Mes infos '} span={'personnelles'} />
      <Container>
        <ContainerHeaderCardBloomer>
          <IconButton
            id="editPersonnalInfos"
            onClick={() => {
              navigate('/me/informations');
            }}
            size="large"
          >
            <EditIcon titleAccess="Editer" />
          </IconButton>
        </ContainerHeaderCardBloomer>
        <ContainerInfo>
          <SingleLineText text={'Nom complet : '} span={bloomer?.fullname} />
          <SingleLineText text={'Mail : '} span={bloomer?.email} />
          <SingleLineText text={'Mail secondaire : '} span={bloomer?.secondaryEmail} />
          <SingleLineText text={'Téléphone : '} span={bloomer?.phoneNumber} />
          <SingleLineText text={'Mon account manager : '} span={bloomer?.accountManager?.fullname} />
        </ContainerInfo>
      </Container>
      <div>
        <div>
          <TitlePart textTitle={'Les infos sur mes '} span={'missions'} />
        </div>
        <ContainerMissionsList>
          {missions?.map((mission, i) => {
            return <BloomerMission mission={mission} key={i} />;
          })}
        </ContainerMissionsList>
      </div>
      <TitlePart textTitle={'Les infos de'} span={'ma société'} />
      <Container>
        {uniqueSociety?.map((society, i) => {
          const mission = society.bloomerBillingMission?.[0];
          const navigateLink = mission
            ? `/society/${society.recordId || 'new'}/mission/${mission.recordId}`
            : `/society/${society.recordId || 'new'}`;
          return (
            <div key={i}>
              <ContainerHeaderCardBloomer>
                <IconButton
                  id="bloomerSociety"
                  onClick={() => {
                    localStorage.removeItem('bloomerSociety');
                    navigate(navigateLink);
                  }}
                  size="large"
                >
                  <EditIcon titleAccess="Editer" />
                </IconButton>
              </ContainerHeaderCardBloomer>
              <ContainerInfo key={i}>
                <SingleLineText text={'Mission : '} span={mission?.label} />
                <SingleLineText text={'Son nom : '} span={society?.name} />
                <SingleLineText text={'Son statut : '} span={society?.status} />
                <SingleLineText text={'Son siret : '} span={society?.siret} />
              </ContainerInfo>
            </div>
          );
        })}
      </Container>
    </ContainerBloomerDashboard>
  );
};
export default BloomerHome;

const ContainerBloomerDashboard = styled('div')(({ theme }) => ({
  width: '50%',
  margin: '2em auto',
  [theme.breakpoints.down('md')]: {
    width: '95%',
    margin: '2em auto',
  },
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  borderRadius: '10px',
  margin: '1.5em auto',
  padding: '1em',
  boxShadow: '2px 2px 20px lightgrey',
}));

const ContainerInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'flex-start',
  margin: '0 1em 1em 1em',
}));

const ContainerHeaderCardBloomer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ContainerMissionsList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
}));

const TitlePart = styled(RawTitlePart)(({ theme }) => ({
  textAlign: 'left',
  marginTop: '2em',
}));

const IconButton = styled(RawIconButton)(({ theme }) => ({
  padding: '0 !important',
}));
