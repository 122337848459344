import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import { styled } from '@mui/material/styles';
import { LoaderSkeleton, Switch, SingleLineText } from '@bloomays-lib/ui.shared';
import { IBilling } from '@bloomays-lib/types.shared';
import { GridColDef, GridValueGetterParams, GridRenderCellParams, gridClasses } from '@mui/x-data-grid-premium';
import RawDataGrid from '../molecules/DataGrid';
import format from 'date-fns/fp/format';
import IconButton from '@mui/material/IconButton';

export type IBillingWithContact = IBilling;

export type BillingDashboardProps = {
  billings?: IBillingWithContact[];
  isTrialPeriod: boolean;
  onChange: () => void;
  loading: boolean;
  updateBilling: (billing: IBillingWithContact) => Promise<void>;
};

const renderIconLabel = (label: string, emoji?: string, onClick?: () => void) => {
  return (
    <IconButton size="small" onClick={onClick} title={label}>
      {emoji || label}
    </IconButton>
  );
};

export const BillingDashboard = ({ billings, isTrialPeriod, onChange, loading }: BillingDashboardProps) => {
  console.log('billings', billings);
  const pageSize = 100;

  const columns: GridColDef<IBillingWithContact>[] = [
    {
      field: 'CustomerName',
      headerName: 'Client',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'Description',
      headerName: 'Titre du poste',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'BillingContact',
      headerName: 'Contact de facturation',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams<IBillingWithContact>) => {
        const contacts = params.row.BillingContact;
        if (!contacts || contacts.length === 0) return '';
        return contacts.map((contact) => contact && `${contact.fullname || ''} (${contact.email || ''})`).join(', ');
      },
    },
    {
      field: 'BillingDate',
      headerName: 'Début de démarrage (début de contrat)',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      type: 'date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<IBillingWithContact>) => {
        return params.row.BillingDate ? new Date(convertDateToStringFormat(params.row.BillingDate)) : null;
      },
      valueFormatter: (params) => {
        if (!params.value) return '';
        return format('dd/MM/yyyy')(params.value as Date);
      },
    },
    {
      field: 'TrialPeriodEndDate',
      headerName: 'Fin de la PE',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      type: 'date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<IBillingWithContact>) => {
        return params.row.TrialPeriodEndDate
          ? new Date(convertDateToStringFormat(params.row.TrialPeriodEndDate))
          : null;
      },
      valueFormatter: (params) => {
        if (!params.value) return '';
        return format('dd/MM/yyyy')(params.value as Date);
      },
    },
    {
      field: 'isInTrialPeriod',
      headerName: "En période d'essai",
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      type: 'boolean',
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<IBillingWithContact>) => {
        if (!params.row.TrialPeriodEndDate) return false;
        const trialEndDate = new Date(convertDateToStringFormat(params.row.TrialPeriodEndDate));
        return trialEndDate > new Date();
      },
    },
    {
      field: 'Billed',
      headerName: 'Facturé',
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      type: 'boolean',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<IBillingWithContact>) => {
        return params.row.Billed ? renderIconLabel('Facturé', '✅') : renderIconLabel('Non facturé', '🚧');
      },
      valueGetter: (params: GridValueGetterParams<IBillingWithContact>) => {
        return params.row.Billed || false;
      },
    },
  ];

  return (
    <div>
      <ContainerToggleText>
        <Switch id="isTrialPeriod" checked={isTrialPeriod} onSwitch={onChange} label={''} />
        <SingleLineText text="Afficher uniquement les bloomers en période d'essai" />
      </ContainerToggleText>
      {loading ? (
        <LoaderSkeleton height={800} width={1200} />
      ) : (
        <DataGrid
          uniqueDatagridId="billing-dashboard"
          initialState={{
            sorting: {
              sortModel: [{ field: 'BillingDate', sort: 'asc' }],
            },
          }}
          fetchLoading={loading}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          hideFooterPagination={false}
          sortingMode="client"
          filterMode="client"
          rows={billings || []}
          getRowId={(row: IBillingWithContact) => row.recordId || ''}
          columns={columns}
          pageSize={pageSize}
          pagination={true}
          sx={{
            width: '100%',
          }}
        />
      )}
    </div>
  );
};

export default BillingDashboard;

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const ContainerToggleText = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  margin: '1em auto',
}));
