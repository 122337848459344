import BloomiesSettings from '../organisms/BloomiesSettings';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { BloomerSettings } from '../organisms/BloomerSettings';
import { Auth0User, Role } from '@bloomays-lib/types.shared';
import { useAuthStore } from '../../stores';

const Settings = (): JSX.Element => {
  const auth = useAuthStore((state) => state.auth);
  let user: Auth0User | undefined | null;
  if (auth) {
    user = auth.user;
  }

  return (
    <>
      {user?.roles?.includes(Role.Admin) && <BloomiesSettings />}
      {user?.roles?.includes(Role.Bloomer) && <BloomerSettings />}
    </>
  );
};

export default WithErrorBoundary(Settings);
