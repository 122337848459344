import ListMissionChoice from '../organisms/ListMissionChoice';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useUserStore } from '../../stores';

const MissionChoice = (): JSX.Element => {
  const dataMe = useUserStore((state) => state.user);
  const isLoading = useUserStore((state) => state.userLoading);
  return <ListMissionChoice data={dataMe?.bloomer?.mission} loading={isLoading} />;
};

export default WithErrorBoundary(MissionChoice);
