import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initMonit } from './helpers/monitoring';
import App from './app/app';
import './main.css';
import TagManager from 'react-gtm-module';
import { Logger } from './services/serviceLogger';
import { Env } from '@bloomays-lib/types.shared';

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_BLOOMERS_API } = import.meta.env;
const audience = VITE_BLOOMERS_API;
const domain = VITE_AUTH0_DOMAIN;
const clientId = VITE_AUTH0_CLIENT_ID;
const env: Env = import.meta.env.VITE_ENV;
if (env === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-58SDQL4',
  };
  TagManager.initialize(tagManagerArgs);
}

const logger = Logger('Main');

initMonit(logger);

const root = createRoot(document.getElementById('root') as HTMLElement);

if (!(domain && clientId)) {
  throw new Error('Error missing VITE_AUTH0_DOMAIN and VITE_AUTH0_CLIENT_ID');
}

root.render(
  <StrictMode>
    <App
      auth0ProviderConfig={{
        domain: domain,
        clientId: clientId,
        audience: audience,
      }}
      env={env}
    />
    ,
  </StrictMode>,
);
