import { useTranslation } from 'react-i18next';

import { SingleLineText } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import RawMailOutlineIcon from '@mui/icons-material/MailOutline';
import { LogoutButton } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useAuthStore } from '../../stores';

const NoRole = (): JSX.Element => {
  const { t } = useTranslation(['random', 'verifyYourMail']);
  const auth = useAuthStore((state) => state.auth);

  return (
    <ContainerDiv>
      <Title textTitle={t('verifyYourMail', { ns: 'firstCo' })} />
      <MailOutlineIcon />
      <SingleLineText text={t('verify', { ns: 'firstCo' })} />
      <SingleLineText text={t('emailVerified', { ns: 'firstCo' })} />
      <LogoutButton logout={(e) => auth?.logout()} textButton="Se reconnecter" type="button" />
    </ContainerDiv>
  );
};

export default WithErrorBoundary(NoRole);

const ContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: `2px 2px 20px ${theme.palette.grey[300]}`,
  borderRadius: '10px',
  width: '40%',
  margin: '1em auto',
  padding: '1em',
  [theme.breakpoints.down('md')]: {
    width: '85%',
  },
}));

const MailOutlineIcon = styled(RawMailOutlineIcon)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '130px !important',
  margin: 'auto',
}));
