import { ErrorBoundary as EBSentry, withErrorBoundary } from '@bloomays-lib/common.monitoring/browser';

import FallbackError from '../organisms/FallbackError';
import { Auth0User } from '@bloomays-lib/types.shared';
import { useAuthStore } from '../../stores';

const ErrorBoundary = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthStore((state) => state.auth);
  let user: Auth0User | null | undefined;
  if (auth) {
    user = auth.user;
  }

  return (
    <EBSentry
      fallback={({ error, componentStack, resetError }) => <FallbackError errorText={error.message} />}
      beforeCapture={(scope) => {
        scope.setUser({
          id: user?.email,
          email: user?.email,
          username: user?.displayName,
        });
      }}
    >
      {children}
    </EBSentry>
  );
};

const GlobalErrorBoundary = ({ children }: { children: JSX.Element }) => {
  return <EBSentry fallback={({ error }) => <FallbackError errorText={error.message} />}>{children}</EBSentry>;
};

const WithErrorBoundary = (WrappedComponent: () => JSX.Element) => {
  return withErrorBoundary(WrappedComponent, {
    fallback: ({ error }) => <FallbackError errorText={error.message} />,
  });
};

export { WithErrorBoundary, ErrorBoundary, GlobalErrorBoundary };
export default WithErrorBoundary;
