import { createBrowserRouter } from 'react-router-dom';
import Bucket from '../components/templates/Bucket';
import Dashboard from '../components/pages/Dashboard';
import Settings from '../components/pages/Settings';
import ProtectedRoute from '../auth/ProtectedRoute';
import SatisfactionClient from '../components/pages/SatisfactionClient';
import { ComponentType } from 'react';
import ElectronicSign from '../components/pages/ElectronicSign';
import BloomerInvitation from '../components/pages/BloomerInvitation';
import MissionDetails from '../components/pages/MissionDetails';
import BillingActivity from '../components/pages/BillingActivity';
import Finance from '../components/pages/Finance';
import File from '../components/pages/File';
import Error from '../components/pages/Error';
import EditCRA from '../components/pages/EditCRA';
import MissionChoice from '../components/pages/MissionChoice';
import CRAManagement from '../components/pages/CRAManagement';
import BloomerPersonnalInformations from '../components/pages/BloomerPersonalInformations';
import BloomerSociety from '../components/pages/BloomerSociety';
import LoginFormBloomer from '../components/pages/LoginFormBloomer';
import TalentView from '../components/pages/TalentView';
import TalentSearch from '../components/pages/TalentSearch';
import NotFound from '../components/pages/NotFound';
import NoRole from '../components/pages/NoRole';
import FallbackConnection from '../components/pages/FallbackConnection';
import BucketLogin from '../components/templates/BucketLogin';
import TalentBoard from '../components/pages/TalentBoard';
import EmbedCRAInviteClient from '../components/pages/EmbedCRAInviteClient';
import AdminSkillManagment from '../components/pages/AdminSkillManagment';
import AdminJobTitleManagment from '../components/pages/AdminJobTitleManagment';
import AdminDirectJobTitleManagment from '../components/pages/AdminDirectJobTitleManagment';
import MissionPlanningDashboard from '../components/pages/MissionPlanningDashboard';
import MonthlyActivityDashboard from '../components/pages/MonthlyActivityDashboard';
import MissionContracts from '../components/pages/MissionContracts';
import Contracts from '../components/pages/Contracts';

const getProtectedComponent = (component: ComponentType): React.ReactNode => {
  return <ProtectedRoute Component={component} />;
};

export const getRouteConfig = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Bucket showFooter={true} />,
      children: [
        {
          index: true,
          element: getProtectedComponent(Dashboard),
        },
        {
          path: 'dashboard',
          element: getProtectedComponent(Dashboard),
        },
        {
          path: 'planningMission',
          element: getProtectedComponent(MissionPlanningDashboard),
        },
        {
          path: 'settings',
          element: getProtectedComponent(Settings),
        },
        {
          path: 'satisfaction/:month/:recordId/:id',
          element: getProtectedComponent(SatisfactionClient),
        },
        {
          path: 'signing/:id',
          element: getProtectedComponent(EmbedCRAInviteClient),
        },
        {
          path: 'jobs/:id/talents',
          element: getProtectedComponent(TalentBoard),
        },
        {
          path: 'electronicSign',
          children: [
            {
              path: ':externalDocumentId',
              element: getProtectedComponent(ElectronicSign),
              children: [
                {
                  path: ':signerId',
                  element: getProtectedComponent(ElectronicSign),
                },
              ],
            },
          ],
        },
        {
          path: 'invite/:token',
          element: getProtectedComponent(BloomerInvitation),
        },
        {
          path: 'missions',
          children: [
            {
              index: true,
              element: getProtectedComponent(MissionChoice),
            },
            {
              path: ':id',
              element: getProtectedComponent(MissionDetails),
            },
            {
              path: ':id/activities',
              element: getProtectedComponent(MonthlyActivityDashboard),
            },
            {
              path: ':id/contracts',
              element: getProtectedComponent(MissionContracts),
            },
            {
              path: ':missionRecordId/cra/:date',
              element: getProtectedComponent(EditCRA),
            },
          ],
        },
        {
          path: 'billingsActivity',
          element: getProtectedComponent(BillingActivity),
        },
        {
          path: 'activities',
          element: getProtectedComponent(MonthlyActivityDashboard),
        },
        {
          path: 'contracts',
          element: getProtectedComponent(Contracts),
        },
        {
          path: 'finance',
          element: getProtectedComponent(Finance),
        },
        {
          path: 'talentsV2',
          children: [
            {
              index: true,
              element: getProtectedComponent(TalentSearch),
            },
            {
              path: ':id',
              element: getProtectedComponent(TalentView),
            },
          ],
        },
        {
          path: 'talents',
          children: [
            {
              index: true,
              element: getProtectedComponent(TalentSearch),
            },
            {
              path: ':id',
              element: getProtectedComponent(TalentView),
            },
          ],
        },
        {
          path: 'error',
          element: getProtectedComponent(Error),
        },
        {
          path: 'mission/:missionRecordId',
          element: getProtectedComponent(CRAManagement),
        },
        {
          path: 'me/informations',
          element: getProtectedComponent(BloomerPersonnalInformations),
        },
        {
          path: 'society/new',
          element: getProtectedComponent(BloomerSociety),
        },
        {
          path: 'society/new/mission/:missionRecordId',
          element: getProtectedComponent(BloomerSociety),
        },
        {
          path: 'society/:recordId',
          element: getProtectedComponent(BloomerSociety),
        },
        {
          path: 'society/:recordId/mission/:missionRecordId',
          element: getProtectedComponent(BloomerSociety),
        },
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: 'admin',
          children: [
            {
              path: 'skills',
              element: getProtectedComponent(AdminSkillManagment),
            },
            {
              path: 'jobTitle',
              element: getProtectedComponent(AdminJobTitleManagment),
            },
            {
              path: 'directJobTitle',
              element: getProtectedComponent(AdminDirectJobTitleManagment),
            },
          ],
        },
      ],
    },
    {
      path: 'forbidden',
      element: <Bucket showFooter={false} />,
      children: [
        {
          index: true,
          element: <FallbackConnection />,
        },
        {
          path: 'norole',
          element: <NoRole />,
        },
        {
          path: 'notrecognized',
          element: <FallbackConnection />,
        },
        {
          path: '*',
          element: <FallbackConnection />,
        },
      ],
    },
    {
      path: 'login',
      element: <BucketLogin />,
      children: [
        {
          index: true,
          element: <LoginFormBloomer />,
        },
      ],
    },
    {
      path: 'files/:path/*',
      element: getProtectedComponent(File),
    },
  ]);
  return router;
};
