import { ActivityDayDetails, ActivityDayType, IActivity, IMission } from '@bloomays-lib/types.shared';
import { dateStrToUTCDate, fixed, isHoliday, isSaturday, isSunday, isWeekEndDay } from '@bloomays-lib/utils.shared';
import format from 'date-fns/format';
import { notify } from './toastify';
import times from 'lodash/fp/times';

export const months: { [key: number]: string } = {
  0: 'Janvier',
  1: 'Février',
  2: 'Mars',
  3: 'Avril',
  4: 'Mai',
  5: 'Juin',
  6: 'Juillet',
  7: 'Aout',
  8: 'Septembre',
  9: 'Octobre',
  10: 'Novembre',
  11: 'Décembre',
};

export const checkIsFinished = (mission: IMission, activitiesDate: Date) => {
  const activitiesFormatted = dateStrToUTCDate(format(activitiesDate, 'dd/MM/yyyy'), 'dd/MM/yyyy').setDate(1);
  const missionFormatted = dateStrToUTCDate(format(new Date(mission.endMission), 'dd/MM/yyyy'), 'dd/MM/yyyy').setDate(
    1,
  );
  const isFinished = activitiesFormatted > missionFormatted;
  if (isFinished) {
    notify('info', 'Tu ne peux pas déclarer de CRA après la fin de ta mission !');
  }
  return isFinished;
};

export const calculPerDay = (arr: ActivityDayDetails[], property: ActivityDayType): number => {
  let initialValue = 0;
  arr?.forEach((el) => {
    const date = dateStrToUTCDate(el.date, 'd/M/yyyy');
    const shoudNotWork = isHoliday(date) || el.isHoliday;
    if (isWeekEndDay(date) && property === 'nbDaysWorked' && !shoudNotWork) {
      initialValue += el.dayWorked || 0;
    } else if (isSaturday(date) && property === 'nbDaysMarkupWorked' && !shoudNotWork) {
      initialValue += el.dayWorked || 0;
    } else if ((isSunday(date) || shoudNotWork) && property === 'nbDaysMarkup2Worked') {
      initialValue += el.dayWorked || 0;
    } else if (property === 'astreinte' && el.astreinte) {
      initialValue += el.astreinte || 0;
    } else {
      return (initialValue += 0);
    }
  });
  return fixed(initialValue);
};

export const dateStrToMonth = (arrData: any[]): any[] => {
  const arrMonthsFilled = arrData.map((el: { activity: IActivity[]; contactOperations: { email: string } }) => {
    if (!el.activity) {
      return [];
    }
    const activities = el.activity;
    return activities?.map((element: IActivity) => {
      const dateToChange = element.month;
      const dateObject = new Date(dateToChange);
      return {
        recordId: element.recordId,
        date: dateObject,
        month: months[dateObject.getMonth()],
        monthNumber: dateObject.getMonth(),
        year: dateObject.getFullYear(),
        nbDaysWorked:
          (element?.nbDaysWorked || 0) + (element.nbDaysMarkupWorked || 0) + (element.nbDaysMarkup2Worked || 0),
        validated: element.validated,
        nbDaysDetails: element.nbDaysDetails,
        signingDocumentId: element.signingDocumentId,
        sign: element.signing,
        contactOperations: el?.contactOperations?.email,
        signedCount: element.signedCount,
        urlCraS3: element.urlCraS3,
        urlInvoicesS3: element.urlInvoicesS3,
      };
    });
  });
  return arrMonthsFilled;
};

export type HourValueLabel = { value: number; label: string };

export const generateOptionsCRA = (maxHours: number): HourValueLabel[] => {
  if (maxHours % 0.5 !== 0) {
    throw new Error('maxHours must be a multiple of 0.5');
  }

  let halfValueInserted = false;
  const intervals: HourValueLabel[] = [{ label: '00h00', value: 0 }];
  const totalSteps = maxHours * 2; // Every hour has 2 steps (30 min each)

  times((i) => {
    const hours = Math.floor((i + 1) / 2);
    const minutes = (i + 1) % 2 === 0 ? '00' : '30';
    const label = `${hours.toString().padStart(2, '0')}h${minutes}`;
    const value = (i + 1) / totalSteps;

    if (!halfValueInserted && value > 0.5) {
      halfValueInserted = true;
      intervals.push({ label: '1/2 j', value: 0.5 });
    }

    if (value === 0.5) {
      halfValueInserted = true;
      intervals.push({ label: '1/2 j', value: 0.5 });
    } else {
      intervals.push({ label, value: fixed(value, 4) });
    }
  }, totalSteps);
  return intervals;
};
