import { StateCreator, create } from 'zustand';
import { INotification } from '@bloomays-lib/types.shared';
import { ApolloClient, ApolloError } from '@apollo/client';
import {
  DELETE_ALL_NOTIFICATION,
  MY_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '@bloomays-lib/adapter.api-bloomer';
import { NotificationCenterContext, NotificationCenterItem } from '@bloomays-lib/ui.shared';
import { convertINotificationToNotifcationCenterItem } from '../services/notificationService';

export type NotificationState = NotificationCenterContext & {
  apolloClient: ApolloClient<any> | null;
  setApolloClient: (client: ApolloClient<any>) => void;
};

export const createNotificationSlice: StateCreator<NotificationState> = (set, get) => {
  return {
    apolloClient: null,
    setApolloClient: (client) => set({ apolloClient: client }),
    user: undefined,
    notificationsLoading: true,
    notificationsError: undefined,
    notifications: undefined,
    notificationsFetch: async () => {
      const { apolloClient } = get();
      if (!apolloClient) return [];
      try {
        const result = await apolloClient.query<{ myNotifications: INotification[] }>({ query: MY_NOTIFICATION });
        if (!result.data.myNotifications) return [];
        const notifications = result.data.myNotifications.map(convertINotificationToNotifcationCenterItem);
        set({
          notifications: notifications,
          notificationsLoading: false,
        });
        return notifications;
      } catch (error: unknown) {
        set({ notificationsError: error as ApolloError | Error, notificationsLoading: false });
        return [];
      }
    },

    notificationsRead: async (notification: NotificationCenterItem): Promise<boolean> => {
      const { apolloClient } = get();
      if (!apolloClient) return false;
      if (!notification.data?.recordId) {
        return false;
      }
      setTimeout(() => {
        set({ notificationsLoading: true });
      }, 0);

      try {
        await apolloClient.mutate({
          mutation: UPDATE_NOTIFICATION,
          variables: {
            recordId: notification.data.recordId,
            read: true,
          },
          refetchQueries: [{ query: MY_NOTIFICATION }],
          awaitRefetchQueries: true,
        });
        return true;
      } catch (error) {
        set({ notificationsError: error as Error });
        return false;
      } finally {
        set({ notificationsLoading: false });
      }
    },

    notificationsReadAll: async (): Promise<number> => {
      const { apolloClient } = get();
      if (!apolloClient) return -1;
      setTimeout(() => {
        set({ notificationsLoading: true });
      }, 0);
      try {
        const { data } = await apolloClient.mutate<{ readAllNotification: number }>({
          mutation: READ_ALL_NOTIFICATION,
          refetchQueries: [{ query: MY_NOTIFICATION }],
          awaitRefetchQueries: true,
        });
        return data?.readAllNotification || 0;
      } catch (error) {
        set({ notificationsError: error as Error });
        return -1;
      } finally {
        set({ notificationsLoading: false });
      }
    },

    notificationsDeleteAll: async (): Promise<number> => {
      const { apolloClient } = get();
      if (!apolloClient) return -1;
      setTimeout(() => {
        set({ notificationsLoading: true });
      }, 0);
      try {
        const result = await apolloClient.mutate<{ deleteAllNotification: number }>({
          mutation: DELETE_ALL_NOTIFICATION,
          refetchQueries: [{ query: MY_NOTIFICATION }],
          awaitRefetchQueries: true,
        });
        set({ notifications: [] });
        return result.data?.deleteAllNotification || 0;
      } catch (error) {
        set({ notificationsError: error as Error });
        return -1;
      } finally {
        set({ notificationsLoading: false });
      }
    },
  };
};

export const useNotificationStore = create<NotificationState>()((...a) => ({
  ...createNotificationSlice(...a),
}));
