import { useAuthStore, useUserStore } from '../../stores';
import ListDetailInfo from '../atoms/ListDetailInfo';

const AuthInfo = (): JSX.Element => {
  const auth = useAuthStore((state) => state.auth);
  const user = useUserStore((state) => state.user);

  return (
    <ListDetailInfo
      list={[
        { text: 'AuthLoading:', span: `${auth?.isLoading}` },
        { text: 'Authenticated:', span: `${auth?.isAuthenticated}` },
        { text: 'AuthUser:', span: `${auth?.user?.displayName}` },
        { text: 'ProfileUser:', span: `${user?.profile.displayName}` },
      ]}
    />
  );
};

export default AuthInfo;
export { AuthInfo };
