import { useParams } from 'react-router-dom';
import TalentSheet from '../organisms/TalentSheet';

const TalentView = (): JSX.Element => {
  // const [openSyncTalent, setOpenSyncTalent] = useState(false);
  const { id } = useParams<{ id: string }>();

  // const handleCloseSyncTalent = () => {
  //   setOpenSyncTalent(false);
  // };

  // const handleOpenSyncTalent = () => {
  //   setOpenSyncTalent(true);
  // };

  return (
    <TalentSheet
      id={id as string}
      origin="talentPage"
      // openSyncTalent={openSyncTalent}
      // handleOpenSyncTalent={handleOpenSyncTalent}
      // handleCloseSyncTalent={handleCloseSyncTalent}
    />
  );
};

export default TalentView;
