import { useEffect, useState } from 'react';
import LoaderGlobal from './LoaderGlobal';
import { Embed } from '../atoms/Embed';

export type FileViewerProps = {
  url: string;
  width?: string;
  height?: string;
  accessToken?: string;
  mimeType?: string;
};

const FileViewer = ({
  url,
  width = '100%',
  height = '100%',
  accessToken,
  mimeType = 'application/pdf',
}: FileViewerProps): JSX.Element => {
  const isBloomaysFile = url.includes('bloomays.com') || url.includes('localhost') || url.includes('files');
  const filePath = url.startsWith('http') ? new URL(url).pathname : url;
  const [loading, setLoading] = useState(true);
  const [dataUrl, setDataUrl] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  if (!filePath) {
    throw new Error('Missing path params');
  }

  useEffect(() => {
    if (!accessToken) {
      setLoading(true);
    }
  }, [accessToken]);

  useEffect(() => {
    let objectUrl: string;
    (async () => {
      if (!filePath) {
        return;
      }
      if (!isBloomaysFile) {
        setLoading(false);
        setDataUrl(url);
        return;
      }

      if (!accessToken) {
        return;
      }
      let urlAPI: string;
      if (!filePath.includes('talents/')) {
        urlAPI = `${import.meta.env.VITE_BLOOMERS_API}${filePath.substring(1)}`;
      } else {
        urlAPI = `${import.meta.env.VITE_TALENTS_API}talent${filePath}`;
      }
      try {
        const response = await fetch(urlAPI, { headers: { Authorization: `Bearer ${accessToken}` } });
        if (!response.ok) {
          const body = await response.json();
          setError(`${response.statusText} : Impossible de charger le fichier (${body.result})`);
          setLoading(false);
          return;
        }
        const blobData = await response.blob();
        const fileBlob = new Blob([blobData], { type: 'application/pdf' });
        objectUrl = window.URL.createObjectURL(fileBlob);
        setDataUrl(objectUrl);
        setLoading(false);
      } catch (error: any) {
        setError(`${error.message} : Impossible de charger le fichier en raison d'une erreur réseau`);
        setLoading(false);
      }
    })();

    return () => {
      window.URL.revokeObjectURL(objectUrl);
    };
  }, [filePath, accessToken, url, isBloomaysFile]);

  if (error) {
    return <div>{error}</div>;
  }

  if (loading || !dataUrl) {
    return <LoaderGlobal waitingText="Chargement du fichier"></LoaderGlobal>;
  }

  if (!isBloomaysFile) {
    return <Embed src={dataUrl} width={width} height={height} type="application/pdf" />;
  }

  return <iframe scrolling="auto" src={dataUrl} width={width} height={height} title={filePath} />;
};

export { FileViewer };
export default FileViewer;
