import { FormEvent, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { UPSERT_BLOOMER, ME } from '@bloomays-lib/adapter.api-bloomer';
import { notify, cancel } from '../../helpers/toastify';
import EditPersonnalInfos from '../organisms/EditPersonnalInfos';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { BrowserBloomer } from '@bloomays-lib/types.shared';
import { errorLogger } from '../../helpers/error';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../stores';

const BloomerPersonnalInformations = (): JSX.Element => {
  const formPersonnal = useRef<any>(null);
  const navigate = useNavigate();
  const dataMe = useUserStore((state) => state.user);
  const [upsertBloomer] = useMutation<{ upsertBloomer: BrowserBloomer }>(UPSERT_BLOOMER, {
    refetchQueries: [
      {
        query: ME,
      },
    ],
  });

  const submitForm = (e: FormEvent<HTMLFormElement>): void => {
    formPersonnal?.current?.validate(e);
  };

  const upsertData = async (bloomer: BrowserBloomer): Promise<BrowserBloomer | undefined> => {
    try {
      notify('info', 'Upload en cours de tes données personnelles, ca va aller très vite!', null, {
        hideProgressBar: false,
        autoClose: 10000,
      });
      const upload = await upsertBloomer({
        variables: {
          input: {
            recordId: bloomer.recordId,
            firstname: bloomer.firstname,
            lastname: bloomer.lastname,
            email: bloomer.email,
            secondaryEmail: bloomer.secondaryEmail,
            phoneNumber: bloomer.phoneNumber,
            slack: bloomer.slack,
            accountManager: [bloomer.accountManagerId],
            credentialID: bloomer.credentialID,
            communicationType: bloomer?.communicationType,
          },
        },
      });
      cancel();
      notify('success', 'Ton profil a bien été mis à jour ! :)', null, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      navigate('/dashboard');
      return upload?.data?.upsertBloomer;
    } catch (error: any) {
      cancel();
      notify('error', 'Profil non mis à jour. Souci avec le serveur, reviens plus tard 😓', error);
      errorLogger(error, {
        extraInfos: 'extraErrorInfos',
        state: {},
      });
    }
  };
  return (
    <div>
      <form onSubmit={submitForm}>
        <EditPersonnalInfos ref={formPersonnal} onValidated={upsertData} me={dataMe || undefined} />
      </form>
    </div>
  );
};

export default WithErrorBoundary(BloomerPersonnalInformations);
