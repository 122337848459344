import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CRM_VISITOR_TOKEN } from '@bloomays-lib/adapter.api-bloomer';
import { Logger } from '../services/serviceLogger';
import { Role } from '@bloomays-lib/types.shared';
import { useAuthStore } from '../stores';

declare global {
  interface Window {
    hsConversationsSettings?: {
      identificationEmail?: string;
      identificationToken?: string;
      loadImmediately?: boolean;
    };
    HubSpotConversations?: {
      widget: {
        load: () => void;
      };
    };
    hsConversationsOnReady?: (() => void)[];
  }
}
const logger = Logger('CRMVisitorProvider');

export const CRMVisitorLoader = () => {
  const [getCRMVisitorToken, { data, error }] = useLazyQuery<{ getCRMVisitorToken: { token: string; email: string } }>(
    CRM_VISITOR_TOKEN,
  );
  const auth = useAuthStore((state) => state.auth);

  const onHubspotReady = () => {
    if (window.HubSpotConversations && window.HubSpotConversations.widget && window.HubSpotConversations.widget.load) {
      logger.debug('Loading HS widget', window.hsConversationsSettings);
      window.HubSpotConversations.widget.load();
    }
  };

  useEffect(() => {
    logger.debug('Start loading CRM Visitor token', auth);
    if (!auth?.isAuthenticated) {
      return;
    }
    if (auth?.user?.roles?.includes(Role.Bloomer) || auth?.user?.roles?.includes(Role.Anonymous)) {
      logger.debug('Loading CRM Visitor token', auth?.user?.roles);
      getCRMVisitorToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    if (data) {
      window.hsConversationsSettings = window.hsConversationsSettings || {};
      window.hsConversationsSettings = {
        identificationEmail: data.getCRMVisitorToken.email,
        identificationToken: data.getCRMVisitorToken.token,
      };
      logger.debug('Applying HS settings CRM Visitor token', window.hsConversationsSettings);
      if (window.HubSpotConversations) {
        onHubspotReady();
      } else {
        /*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
        window.hsConversationsOnReady = [onHubspotReady];
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      logger.warn(`Unable to get CRM Visitor Token ${error}`);
      if (window.HubSpotConversations) {
        onHubspotReady();
      } else {
        /*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
        window.hsConversationsOnReady = [onHubspotReady];
      }
    }
  }, [error]);

  return null;
};

export function CRMVisitorProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <CRMVisitorLoader />
      {children}
    </>
  );
}
