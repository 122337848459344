import { StateCreator, create } from 'zustand';
import { BrowserMe } from '@bloomays-lib/types.shared';
import { ME } from '@bloomays-lib/adapter.api-bloomer';
import { Logger } from '../services/serviceLogger';
import { ApolloClient } from '@apollo/client';
import { getApolloErrorCode } from '../helpers/error';
const logger = Logger('userStore');

export interface UserState {
  apolloClient: ApolloClient<any> | null;
  setApolloClient: (client: ApolloClient<any>) => void;
  user?: BrowserMe | null;
  userLoading: boolean;
  userError?: Error;
  userInit: () => void;
  userFetch: (reason?: string) => Promise<void>;
}

export const createUserSlice: StateCreator<UserState> = (set, get) => {
  const handleUserError = (error: any, reason?: string) => {
    const errorCode = getApolloErrorCode(error);
    if (errorCode === 'UNKNOWN_BLOOMER' || errorCode === 'UNAUTHENTICATED') {
      // errorCode === 'UNAUTHENTICATED' c'est quand on a l'email qui n'est pas verifé
      set({ user: null, userLoading: false });
      return;
    }
    if (errorCode === 'TOKEN_EXPIRED') {
      // errorCode === 'TOKEN_EXPIRED' on laisse passer le front qui va gerer
      set({ user: null, userLoading: false });
      return;
    }
    const managedError = new Error('Impossible de charger le profil utilisateur', { cause: error });
    set({ userError: managedError, userLoading: false });
    logger.debug('fetch user ❌', { reason });
    throw error;
  };

  return {
    apolloClient: null,
    setApolloClient: (client) => set({ apolloClient: client }),
    user: undefined,
    userLoading: false,
    userError: undefined,
    userInit: () => {
      set({ user: undefined, userLoading: false });
      logger.debug('init user ✅');
    },
    userFetch: async (reason) => {
      logger.debug('fetch user 👀', { reason });
      const { apolloClient } = get();
      if (!apolloClient) {
        throw new Error('Apollo Client Not Initialized');
      }
      set({ userLoading: true });
      try {
        apolloClient.watchQuery({ query: ME }).subscribe({
          next: ({ data }) => {
            set({ user: data?.me, userLoading: false });
            logger.debug('refetch user ✅', { reason });
          },
          error: (error) => {
            return handleUserError(error, reason);
          },
        });
        await apolloClient.query<{ me: BrowserMe }>({ query: ME });
      } catch (error: any) {
        return handleUserError(error, reason);
      }
    },
  };
};

export const useUserStore = create<UserState>()((...a) => ({
  ...createUserSlice(...a),
}));
